import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

const ContentPage = ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Alcool au volant 2ième infraction - Comment s'en sortir?"
            description="Vous avez été arrêté pour conduite avec les facultés affaiblies par l’alcool pour une deuxième fois ? Nous vous expliquons tout ce qu’il faut savoir sur une deuxième condamnation et comment s’en sortir !"
            fr={undefined}
            lawType="criminal"
            specialtyText="pour Alcool au volant">
            <p>
                Faire face pour la deuxième fois à des accusations criminelles
                pour alcool au volant après une première condamnation est une
                situation grave qui expose à des sanctions encore plus sévères.{' '}
            </p>
            <p>
                <strong>
                    En plus d’une suspension prolongée du permis de conduire,
                    les contrevenants risquent une peine d’emprisonnement.
                </strong>
            </p>
            <p>
                Cependant, selon les circonstances, il est possible d’obtenir un
                acquittement ou, en cas de condamnation, de limiter les
                conséquences en accédant à certaines mesures, comme un permis
                restreint.
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Qu’est-ce que la conduite avec les facultés affaiblies par l’alcool ?"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Dans cet article, nous vous expliquons les
                                conséquences d’une deuxième accusation d’alcool
                                au volant et les solutions pour s’en sortir, que
                                ce soit par un acquittement ou en obtenant un
                                permis restreint en cas de condamnation!
                            </strong>
                        </p>
                    </td>
                </tr>
            </table>
            <h2>
                Qu’est-ce que la conduite avec facultés affaiblies par l’alcool
                ?
            </h2>
            <p>
                La conduite avec facultés affaiblies par l'alcool est une
                infraction criminelle prévue à l'article{' '}
                <Link to="https://laws-lois.justice.gc.ca/fra/lois/c-46/section-320.14.html">
                    320.14
                </Link>{' '}
                du <em>Code criminel</em>. Cette infraction survient
                principalement dans deux situations:
            </p>
            <ol>
                <li>
                    La première concerne les individus qui conduisent un
                    véhicule alors que leur capacité est altérée par l'alcool,
                    les drogues, ou une combinaison des deux, même si cette
                    altération est minime.
                </li>
                <li>
                    La seconde situation se produit lorsque la personne présente
                    une concentration d'alcool dans le sang égale ou supérieure
                    à 80 milligrammes par 100 millilitres de sang, et ce, dans
                    les deux heures suivant la cessation de la conduite.
                </li>
            </ol>
            <p>
                Il existe néanmoins une exception notable à cette règle. Si une
                personne consomme de l’alcool après avoir cessé de conduire et
                qu'elle n’avait pas de raison de croire qu'elle serait tenue de
                fournir un échantillon d’haleine ou de sang, elle ne peut être
                jugée coupable de cette infraction.
            </p>
            <h2>
                Quelles sont les conséquences d’une deuxième condamnation pour
                alcool au volant au Québec ?
            </h2>
            <p>
                Une deuxième condamnation pour conduite avec facultés affaiblies
                par l’alcool dans un délai de 10 ans entraîne des sanctions plus
                sévères. De plus, dès l’arrestation pour cette deuxième
                infraction, des sanctions sont immédiatement appliquées.
            </p>
            <p>
                Voici un aperçu des sanctions appliquées lors de l'arrestation
                et après la condamnation en cas de deuxième infraction pour
                conduite avec facultés affaiblies au Québec :
            </p>
            <h3>Sanctions à l’arrestation</h3>
            <p>
                Lorsqu’un conducteur est arrêté pour une deuxième infraction
                liée à l'alcool au volant, des mesures immédiates sont
                appliquées.{' '}
            </p>
            <ul>
                <li>
                    La première sanction consiste en
                    <strong>
                        {' '}
                        la suspension immédiate du permis de conduire pour une
                        durée de 90 jours
                    </strong>
                    . Cela signifie que le conducteur ne pourra pas conduire
                    pendant cette période, ni obtenir un permis restreint.
                </li>
                <li>
                    De plus, le véhicule du contrevenant sera immédiatement{' '}
                    <strong>saisi pour 90 jours</strong>, ce qui empêche
                    temporairement son utilisation. Cette mesure peut également
                    entraîner des <strong>coûts supplémentaires</strong> pour
                    récupérer le véhicule à la fin de la période de saisie.
                </li>
            </ul>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Sanctions à l'arrestation pour conduite avec facultés affaiblies !"
                    alt=""
                />
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                <em>Mainlevée de saisie</em>
                            </strong>
                        </p>
                        <p>
                            Dans certains cas, il est possible de faire une
                            demande de mainlevée de saisie,{' '}
                            <strong>
                                permettant au propriétaire du véhicule de
                                récupérer son bien avant la fin de la période de
                                saisie imposée
                            </strong>
                            .{' '}
                        </p>
                        <p>
                            Cette procédure est particulièrement utile lorsque
                            le conducteur arrêté pour alcool au volant n'est pas
                            le propriétaire du véhicule.{' '}
                        </p>
                        <p>
                            Après la saisie par un agent de la paix, le véhicule
                            est généralement conservé à la fourrière pendant une
                            période déterminée. Si la demande de mainlevée est
                            acceptée, le véhicule peut être restitué plus tôt,
                            sous réserve de l'approbation d'un juge ou de la
                            SAAQ.
                        </p>
                    </td>
                </tr>
            </table>
            <ul>
                <li>
                    Le conducteur peut aussi se voir imposer le{' '}
                    <strong>
                        Programme d’évaluation et de réduction du risque de
                        conduite avec les capacités affaiblies
                    </strong>{' '}
                    (PERRCCA). Ce programme est une évaluation s’adressant aux
                    conducteurs arrêtés ou condamnés une ou plusieurs fois pour
                    conduite avec les facultés affaiblies par l'alcool ou la
                    drogue.
                </li>
            </ul>
            <p>
                L’évaluation permet de déterminer si les habitudes de
                consommation d’alcool du conducteur nuisent à sa conduite. Elle
                est essentielle pour évaluer si un suivi ou d'autres mesures
                doivent être imposés. Lors de l’évaluation, le conducteur devra,
                entre autres, répondre à des questions concernant :
            </p>
            <ul>
                <li>Ses habitudes de consommation d'alcool</li>
                <li>Ses habitudes de conduite</li>
                <li>
                    Sa perception de la conduite avec les capacités affaiblies
                </li>
            </ul>
            <h3>Sanctions après déclaration de culpabilité criminelle</h3>
            <p>
                Une fois qu'une personne est reconnue coupable d'une deuxième
                infraction de conduite avec facultés affaiblies, plusieurs
                autres sanctions vont s’appliquer, notamment:
            </p>
            <h4>Amende</h4>
            <p>
                Le contrevenant devra s’acquitter d'une{' '}
                <strong>amende minimale de 1 000 $,</strong> mais celle-ci peut
                être plus élevée en fonction de la concentration d'alcool dans
                le sang au moment de l'infraction.
            </p>
            <p>Voici les amendes imposées en fonction du taux d'alcoolémie :</p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Concentration d'alcool dans le sang</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Amende</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>80-119 mg d'alcool par 100 ml de sang</p>
                    </td>
                    <td>
                        <p>1 000 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>120-159 mg d'alcool par 100 ml de sang</p>
                    </td>
                    <td>
                        <p>1 500 $</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>160 mg ou plus par 100 ml de sang</p>
                    </td>
                    <td>
                        <p>2 000 $</p>
                    </td>
                </tr>
            </table>
            <h4>Emprisonnement</h4>
            <p>
                Une peine d’
                <strong>
                    emprisonnement obligatoire d’au moins 30 jours
                </strong>{' '}
                est imposée dans le cadre d’une deuxième infraction pour alcool
                au volant.{' '}
            </p>
            <p>
                Cette peine de prison peut s'étendre jusqu'à 10 ans dans
                certains cas extrêmes, bien que la durée exacte dépendra des
                circonstances spécifiques de l’infraction et de la décision du
                tribunal.{' '}
            </p>
            <h4>Suspension et révocation du permis de conduire</h4>
            <p>
                Comme mentionné plus haut, dès l’arrestation, le permis de
                conduire est suspendu pour une durée de 90 jours par la SAAQ.
                Cependant, si le conducteur est reconnu coupable, cette{' '}
                <strong>suspension </strong>peut être prolongée pour une{' '}
                <strong>durée minimale de deux ans</strong>.{' '}
            </p>
            <p>
                D’ailleurs, le tribunal ordonne la <strong>révocation</strong>{' '}
                du permis de conduire pour une période de{' '}
                <strong>trois ou cinq ans, voire plus</strong>, selon sa
                décision.
            </p>
            <h4>Restrictions sur les véhicules</h4>
            <p>
                Le tribunal impose également des{' '}
                <strong>restrictions sur les véhicules</strong>, notamment
                l’interdiction d’immatriculer, d’acquérir, de louer ou de mettre
                en circulation un véhicule à son nom
            </p>
            <h4>Programme d’évaluation</h4>
            <p>
                Le conducteur peut également être ordonné d'accomplir le
                Programme d’évaluation et de réduction du risque de conduite
                avec les capacités affaiblies (PERRCCA). Toutefois, il est
                important de préciser que si le conducteur a déjà suivi ce
                programme lors de son arrestation, il est peu probable qu'il
                soit à nouveau contraint de le refaire.
            </p>
            <h4>Dispositif antidémarrage</h4>
            <p>
                Une autre sanction imposée par le tribunal est l'installation
                permanente d’un <strong>dispositif antidémarrage</strong> dans
                le véhicule du contrevenant.{' '}
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Installation d’un dispositif antidémarrage!"
                    alt=""
                />
            </p>
            <p>
                Ce dispositif empêche le véhicule de démarrer si le conducteur a
                une concentration d'alcool trop élevée dans son sang. Il doit
                être installé pour une période de 10 ans, après quoi une demande
                de retrait peut être faite.{' '}
            </p>
            <p>
                En somme, une deuxième condamnation pour alcool au volant au
                Québec entraîne des sanctions sévères, incluant des amendes
                substantielles, une peine d'emprisonnement, la suspension et
                révocation du permis, des restrictions sur l'utilisation des
                véhicules, et l’installation d’un dispositif antidémarrage.{' '}
            </p>
            <h2>
                Comment se sortir d'une deuxième infraction pour conduite avec
                facultés affaiblies par l'alcool ?
            </h2>
            <p>
                Faire face à une deuxième accusation de conduite avec facultés
                affaiblies par l'alcool est une situation extrêmement stressante
                et aux conséquences potentiellement lourdes!
            </p>
            <p>
                En effet, les tribunaux considèrent une récidive comme une faute
                plus grave, ce qui peut entraîner des sanctions plus sévères,
                telles qu'une peine d'emprisonnement, une suspension prolongée
                du permis de conduire et des amendes substantielles.{' '}
            </p>
            <p>
                Toutefois, il existe deux stratégies majeures pour atténuer ces
                conséquences et même éviter une condamnation : tenter d'être
                acquitté ou, à défaut, obtenir un permis restreint permettant de
                continuer à conduire sous certaines conditions.
            </p>
            <h3>L'acquittement : éviter une condamnation</h3>
            <p>
                Comme toute infraction criminelle, être accusé de conduite avec
                facultés affaiblies ne signifie pas automatiquement être reconnu
                coupable.{' '}
                <strong>
                    Le procureur de la Couronne doit prouver hors de tout doute
                    raisonnable que l'infraction a été commise, s’il échoue,
                    l’accusé est acquitté.
                </strong>
            </p>
            <p>
                De plus, une personne arrêtée pour conduite avec facultés
                affaiblies{' '}
                <strong>bénéficie de protections constitutionnelles</strong>. En
                cas de non-respect de ces droits, certains éléments de preuve
                essentiels pour établir la culpabilité de l'accusé peuvent être
                exclus, ce qui peut conduire à son acquittement.
            </p>
            <p>
                D’ailleurs, dans certains cas, si la violation des droits
                constitutionnels est jugée très grave et qu'aucune autre
                réparation ne peut être accordée pour réparer cette violation,
                le tribunal peut même{' '}
                <strong>ordonner l'arrêt des procédures</strong>, ce qui
                équivaut également à un acquittement.
            </p>
            <h4>
                {' '}
                Bref, il existe plusieurs moyens de défense qui peuvent mener à
                un acquittement:
            </h4>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Moyen de défense</strong>
                        </p>
                    </td>
                    <td>
                        <p>
                            <strong>Explication</strong>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>La violation du droit à l'assistance d'un avocat</p>
                    </td>
                    <td>
                        <p>
                            En vertu de l’article 10b) de la{' '}
                            <em>Charte canadienne des droits et libertés</em>,
                            toute personne arrêtée a le droit fondamental de
                            consulter un avocat sans délai.{' '}
                        </p>
                        <p>
                            Si ce droit est violé, la preuve obtenue peut être
                            déclarée irrecevable. Par exemple, si une personne
                            est privée de l’assistance d’un avocat sans raison
                            valable avant un alcootest, les résultats pourraient
                            être exclus du dossier.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            La violation du droit d’être informé des motifs de
                            l’arrestation
                        </p>
                    </td>
                    <td>
                        <p>
                            En vertu de l'article 10a) de la Charte canadienne,
                            toute personne arrêtée a droit de savoir
                            immédiatement pourquoi elle est arrêtée. Dans le cas
                            d'une accusation de conduite avec facultés
                            affaiblies par l’alcool, la police doit expliquer
                            clairement les raisons de l'arrestation.{' '}
                        </p>
                        <p>
                            Ce droit est essentiel pour que la personne puisse
                            comprendre la situation, prendre les bonnes
                            décisions, comme consulter un avocat, et être
                            informée des conséquences possibles.{' '}
                        </p>
                        <p>
                            Si la police omet cette information, cela constitue
                            une violation, rendant les preuves obtenues
                            potentiellement irrecevables, pouvant mener à
                            l'acquittement.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            La violation du droit d'être jugé dans un délai
                            raisonnable
                        </p>
                    </td>
                    <td>
                        <p>
                            Une personne accusée de conduite avec facultés
                            affaiblies doit être jugée dans un délai
                            raisonnable, comme l’exige l'article 11b) de la
                            Charte canadienne. La décision{' '}
                            <Link to="https://www.canlii.org/fr/ca/csc/doc/2016/2016csc27/2016csc27.html">
                                <em>R. c. Jordan</em>
                            </Link>{' '}
                            de la Cour suprême a fixé un délai de 18 mois pour
                            les procès.{' '}
                        </p>
                        <p>
                            Si ce délai n’est pas respecté, un avocat peut
                            demander l'arrêt des procédures pour violation de ce
                            droit. Si le juge confirme la violation, il peut
                            ordonner l’arrêt des procédures, ce qui équivaut à
                            un acquittement.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Erreurs dans l'analyse des échantillons d'haleine ou
                            de sang
                        </p>
                    </td>
                    <td>
                        <p>
                            Les échantillons d'haleine doivent être prélevés
                            selon des protocoles stricts. Une mauvaise
                            calibration de l'éthylomètre, des délais non
                            respectés entre les tests, ou des erreurs dans la
                            chaîne de possession des échantillons peuvent
                            entraîner le rejet de la preuve.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Acquittement pour conduite avec les facultés affaiblies par l'alcool!"
                    alt=""
                />
            </p>
            <p>
                En résumé, bien qu'une accusation de conduite avec facultés
                affaiblies soit sérieuse, plusieurs moyens de défense peuvent
                conduire à un acquittement. En cas de violation des droits
                constitutionnels ou d'erreurs dans la procédure, les preuves
                déterminantes peuvent être exclues ou l'arrêt des procédures
                peut être prononcé.
            </p>
            <ol>
                <li>Obtenir un permis restreint après une condamnation</li>
            </ol>
            <p>
                Si l'acquittement n'est pas envisageable et qu'une condamnation
                survient, il existe des solutions pour limiter l'interdiction de
                conduire imposée, notamment par l'obtention d'un permis
                restreint.
            </p>
            <p>
                Bien que soumis à des conditions strictes, ce permis permet à la
                personne condamnée de conduire à des moments spécifiques pendant
                la période d'interdiction. Le permis restreint nécessite
                l'installation d'un antidémarreur éthylométrique, qui empêche le
                démarrage du véhicule si de l'alcool est détecté.
            </p>
            <h4>
                Un permis restreint ne peut pas être obtenu qu’après avoir purgé
                sa peine!
            </h4>
            <p>
                Il est important de savoir qu'un permis restreint ne peut pas
                être obtenu immédiatement après l'arrestation.{' '}
            </p>
            <p>
                En effet, dès qu'une personne est arrêtée pour une deuxième fois
                pour conduite avec facultés affaiblies, son permis est suspendu
                pendant 90 jours par la SAAQ. Durant cette période, il est
                impossible de demander un permis restreint ou d'installer un
                antidémarreur éthylométrique.
            </p>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>
                                Le permis restreint ne peut être demandé
                                qu'après avoir purgé la peine imposée, et il
                                faut également attendre trois mois après le
                                jugement.
                            </strong>{' '}
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Par exemple, si une personne est reconnue coupable pour une
                deuxième infraction, qu'elle a payé l'amende et purgé sa peine
                d’emprisonnement, elle pourra alors demander un permis
                restreint.
            </p>
            <h4>Conditions d'éligibilité pour un permis restreint</h4>
            <p>
                Tous les conducteurs ne sont pas éligibles à un permis
                restreint. Certaines conditions doivent être remplies pour
                pouvoir en faire la demande :
            </p>
            <ul>
                <li>
                    <strong>Non-opposition du juge</strong> : Si le juge a
                    explicitement interdit l'obtention d'un permis restreint,
                    cette option n'est pas possible.
                </li>
                <li>
                    <strong>Permis valide au moment de l'infraction</strong> :
                    Le conducteur doit avoir un permis valide lors de
                    l'infraction.
                </li>
                <li>
                    <strong>Permis standard</strong> : Les conducteurs
                    titulaires d'un permis d'apprenti conducteur (classe 6) ne
                    peuvent pas obtenir de permis restreint.
                </li>
            </ul>
            <p>
                Si ces conditions sont remplies, un conducteur peut envisager le
                permis restreint.
            </p>
            <h4>Démarches pour obtenir un permis restreint</h4>
            <p>
                Le processus pour obtenir un permis restreint après une deuxième
                condamnation pour conduite avec facultés affaiblies est précis
                et comporte plusieurs étapes, telles que:
            </p>
            <ol>
                <li>
                    <strong>Louer un antidémarreur éthylométrique:</strong> La
                    première étape consiste à louer un antidémarreur
                    éthylométrique. Il est nécessaire de louer cet appareil
                    auprès d'un fournisseur agréé par le gouvernement.
                </li>
                <li>
                    <strong>Rassembler les documents nécessaires</strong>: Avant
                    de se rendre à la SAAQ, le conducteur doit s'assurer qu'il
                    dispose de tous les documents nécessaires. Cela inclut le
                    contrat de location de l'antidémarreur éthylométrique, une
                    pièce d'identité valide, ainsi que tout document relatif à
                    la condamnation et à la suspension de son permis.
                </li>
            </ol>
            <p>
                <Img
                    fluid={data.img4.childImageSharp.fluid}
                    title="Démarches pour obtenir un permis restreint!"
                    alt=""
                />
            </p>
            <ol>
                <li>
                    <strong>Se rendre à la SAAQ:</strong> Une fois les documents
                    en ordre, le conducteur doit se rendre dans un point de
                    service de la SAAQ pour soumettre sa demande de permis
                    restreint.{' '}
                </li>
                <li>
                    <strong>Payer les frais associés</strong> L'obtention d'un
                    permis restreint implique plusieurs frais. Ces frais
                    couvrent la délivrance du permis, l'installation de
                    l'antidémarreur dans le véhicule, ainsi que la location
                    mensuelle de l'appareil.{' '}
                </li>
                <li>
                    <strong>
                        Installation de l'antidémarreur éthylométrique
                    </strong>{' '}
                    Une fois le permis restreint accordé, l'antidémarreur doit
                    être installé dans le véhicule. L'installation doit être
                    réalisée par un professionnel agréé, selon les normes
                    établies par la SAAQ.{' '}
                </li>
                <li>
                    <strong>Suivi et vérifications régulières</strong> Durant la
                    période où le conducteur détient un permis restreint, il
                    devra se soumettre à des vérifications régulières de
                    l'antidémarreur éthylométrique pour s'assurer de son bon
                    fonctionnement.{' '}
                </li>
            </ol>
            <table>
                <tr>
                    <td>
                        <h4>Restrictions supplémentaires</h4>
                        <p>
                            Il est important de noter qu'avec un permis
                            restreint,{' '}
                            <strong>
                                il est impératif de respecter strictement les
                                conditions imposées par la SAAQ.{' '}
                            </strong>
                            Cela inclut la conduite uniquement d’un véhicule
                            équipé d’un antidémarreur éthylométrique.{' '}
                        </p>
                        <p>
                            Le non-respect de ces conditions peut entraîner des
                            sanctions sévères, telles que des amendes allant de
                            1 500 $ à 3 000 $, la révocation du permis restreint
                            et la mise en fourrière du véhicule.
                        </p>
                        <p>
                            D’ailleurs, le conducteur ne peut pas conduire des
                            véhicules hors route, car ces véhicules ne peuvent
                            pas être équipés d'un antidémarreur éthylométrique.
                            Ainsi, les possibilités de conduite sous un permis
                            restreint sont limitées à des véhicules spécifiques
                            et sous des conditions précises.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                Bref, faire face à une deuxième infraction pour conduite avec
                facultés affaiblies par l'alcool est une situation difficile,
                mais pas sans issue. Il est possible de contester la preuve et
                d'invoquer des moyens de défense pour éviter une condamnation.
                Toutefois, en cas de condamnation, l'obtention d'un permis
                restreint peut aider à minimiser l'impact sur la vie
                quotidienne.
            </p>
            <p>
                Dans tous les cas, consulter un avocat est fortement recommandé
                pour déterminer la meilleure stratégie à adopter et optimiser
                les chances de conserver son droit de conduire!
            </p>
            <h2>
                Vous êtes accusé de conduite avec les facultés affaiblies par
                l’alcool pour une deuxième fois? Consultez un avocat spécialisé
                en droit criminel!
            </h2>
            <p>
                Si vous avez été arrêté pour conduite avec les facultés
                affaiblies par l'alcool et que des accusations criminelles ont
                été portées contre vous, il est fortement conseillé de consulter
                un avocat spécialisé en droit criminel ! Un avocat expérimenté
                dans ce domaine pourra vous offrir l’assistance juridique
                nécessaire pour défendre vos droits.
            </p>
            <p>
                En effet, faire appel à un avocat spécialisé en droit criminel
                vous assure de recevoir la meilleure défense possible. Selon les
                circonstances, cela peut même mener à un acquittement. Ne
                sous-estimez pas l'importance d'un avocat, car son intervention
                peut véritablement influencer l’issue de votre affaire!
            </p>
            <p>
                <Img
                    fluid={data.img5.childImageSharp.fluid}
                    title="Avocat pour conduite avec les facultés affaiblies!"
                    alt=""
                />
            </p>
            <p>
                <strong>
                    Grâce à notre formulaire simple et gratuit, vous serez en
                    mesure de trouver rapidement un avocat spécialisé en droit
                    criminel !
                </strong>
            </p>
            <p>
                <strong>
                    Suite à votre demande, nous vous mettrons rapidement en
                    contact avec un avocat criminaliste près de chez vous. Ce
                    processus ne nécessite aucun engagement de votre part !
                </strong>
            </p>
        </SeoPage>
    )
}

export default ContentPage

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/definition-conduite-facultes-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/sanctions-arrestation-conduite-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/installation-dispositif-antidémarrage-quebec.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/acquittement-conduite-facultes-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/demarches-obtenir-permis-restreint.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img5: file(
            relativePath: {
                eq: "alcool-volant-deuxieme-infraction/avocat-conduite-affaiblies-alcool.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
